'use client';

import clsx from 'clsx';
import type { PropsWithChildren } from 'react';
import { useTheme } from '~/features/theme/ThemeProvider';
import styles from './CircularPatternWrapper.module.css';

export const CircularPatternWrapper = ({ children }: PropsWithChildren) => {
  const theme = useTheme();

  if (theme.isLight) {
    return <>{children}</>;
  }

  return (
    <div className={clsx('h-full bg-gray-10 opacity-80', styles.patternWrapper)}>
      <div className={clsx('h-full', styles.patternWrapperContainer)}>
        {children}
      </div>
    </div>
  );
};
