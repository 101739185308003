import type { PropsWithChildren } from 'react';
import { Badge } from '~/components/catalyst/badge-catalyst';
import { Typo } from '~/components/ui/typography';
import { ContactSupportDialog } from '../support/ContactSupportDialog';

export type FullPageErrorProps = PropsWithChildren<{
  statusCode?: number;
  title?: string;
  message?: string;
}>;

export const FullPageError = (props: FullPageErrorProps) => {
  return (
    <main className="my-8 flex size-full flex-col items-center justify-center gap-8">
      <div className="space-y-3 text-center">
        <Badge color="cyan">{props.statusCode ?? 401}</Badge>
        <Typo variant="h1">{props.title ?? 'Something went wrong.'}</Typo>
        <Typo variant="default">
          {props.message ?? 'An unexpected error occurred.'}
        </Typo>
      </div>
      <div className="flex items-center gap-4">
        {props.children}
        <ContactSupportDialog />
      </div>
    </main>
  );
};
