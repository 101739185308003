'use client';

import { useMutation } from '@tanstack/react-query';
import { signIn } from 'next-auth/react';
import posthog from 'posthog-js';
import { Button } from '~/components/ui/button';
import { Logo } from '~/components/ui/icons/icon';

export type SignInWithGitHubButtonProps = {
  callbackUrl: string;
};

export const SignInWithGitHubButton = ({
  callbackUrl,
}: SignInWithGitHubButtonProps) => {
  const mutation = useMutation({
    mutationFn: async () => {
      posthog.capture('SignIn', {
        provider: 'github',
      });
      return signIn('github', { callbackUrl, redirect: true });
    },
  });

  return (
    <Button
      variant="outline"
      className="flex-1"
      onClick={() => mutation.mutate()}
      loading={mutation.status !== 'idle'}
    >
      <Logo icon="github" size={16} />
      Sign in with GitHub
    </Button>
  );
};
