'use client';

import { useMutation } from '@tanstack/react-query';
import { signIn } from 'next-auth/react';
import posthog from 'posthog-js';
import { Button } from '~/components/ui/button';
import { Logo } from '~/components/ui/icons/icon';

export type SignInWithGoogleProps = {
  callbackUrl: string;
};

export const SignInWithGoogle = ({ callbackUrl }: SignInWithGoogleProps) => {
  const mutation = useMutation({
    mutationFn: async () => {
      posthog.capture('SignIn', {
        provider: 'google',
      });
      return signIn('google', { callbackUrl, redirect: true });
    },
  });

  return (
    <Button
      className="flex-1"
      onClick={() => mutation.mutate()}
      variant="outline"
      loading={mutation.status !== 'idle'}
    >
      <Logo icon="google" size={16} />
      Sign in with Google
    </Button>
  );
};
