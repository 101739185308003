'use client';

import { useRouter } from 'next/navigation';
import { useEffect } from 'react';
import { FullPageError } from '../error/FullPageError';

export type RedirectPageProps = {
  redirectUrl: string;
};

export const RedirectPage = (props: RedirectPageProps) => {
  const router = useRouter();

  useEffect(() => {
    router.push(props.redirectUrl);
  }, [props.redirectUrl, router]);

  return (
    <FullPageError
      statusCode={301}
      title="Redirecting..."
      message={`Redirecting to ${props.redirectUrl}`}
    />
  );
};
