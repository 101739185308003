'use client';

import type { ComponentPropsWithoutRef } from 'react';
import type { TwcComponentProps } from 'react-twc';
import { twx } from '~/lib/twx';
import { cn } from '~/lib/utils';
import { Typo } from '../ui/typography';

type LayoutProps = TwcComponentProps<'button'> & {
  size?: 'base' | 'lg' | 'sm';
  spacing?: 'base' | 'lg';
};

export const Layout = twx.div<LayoutProps>((props) => [
  `max-w-5xl flex-wrap w-full flex gap-4 mx-auto px-4`,
  {
    'max-w-7xl': props.size === 'lg',
    'max-w-3xl': props.size === 'sm',
  },
  {
    'my-4 lg:my-8': props.spacing === 'lg',
  },
]);

export const LayoutHeader = twx.header`flex items-start gap-1 flex-col w-full md:flex-1 min-w-[200px]`;

export const LayoutTitle = (props: ComponentPropsWithoutRef<'h1'>) => {
  return <Typo {...props} variant="h2" className={cn(props.className)} />;
};

export const LayoutDescription = (props: ComponentPropsWithoutRef<'p'>) => {
  return <Typo {...props} className={cn(props.className)} />;
};

export const LayoutActions = twx.div`flex items-center gap-2`;

export const LayoutContent = twx.div`w-full`;
