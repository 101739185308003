'use client';

import { useMutation } from '@tanstack/react-query';
import { signIn } from 'next-auth/react';
import { useSearchParams } from 'next/navigation';
import posthog from 'posthog-js';
import type { FormEvent } from 'react';
import { useState } from 'react';
import { toast } from 'sonner';
import { z } from 'zod';
import { Button } from '~/components/ui/button';
import { Input } from '~/components/ui/input';
import { Label } from '~/components/ui/label';
import type { SignInFormProps } from './SignInForm';

const emailSchema = z.string().email();

export const EmailForm = (props: SignInFormProps) => {
  const params = useSearchParams();

  const callbackUrl = props.callbackUrl ?? params.get('callbackUrl') ?? '/home';
  const defaultEmail = params.get('email') ?? '';

  const [email, setEmail] = useState(defaultEmail);

  const signInMutation = useMutation({
    mutationFn: async () => {
      const result = signIn('resend', { email: email.toLowerCase(), callbackUrl });
      toast.success(`Magic link send to your email ${email}`);
      posthog.capture('SignIn', {
        provider: 'email',
      });
      return result;
    },
  });

  const onSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    signInMutation.mutate();
  };

  const safeEmail = emailSchema.safeParse(email);

  return (
    <form onSubmit={onSubmit} className="w-full">
      <div className="flex flex-col items-center gap-3 md:flex-row md:items-end">
        <div className="flex w-full flex-col gap-1.5">
          <Label htmlFor="email">Email</Label>
          <Input
            className="w-full"
            type="email"
            name="email"
            value={email}
            onChange={(event) => setEmail(event.currentTarget.value)}
          />
        </div>
        <Button
          type="submit"
          className="w-full items-center md:w-40"
          loading={signInMutation.isPending}
          disabled={!safeEmail.success}
        >
          Login
        </Button>
      </div>
    </form>
  );
};
